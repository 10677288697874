import React, { useEffect, useRef } from "react";
import "./TextEditor.css";

const TextEditor = ({ content, cssClass }) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (!content) return;

    const slotHtml = document.createRange().createContextualFragment(content); // Create a 'tiny' document and parse the html string
    contentRef.current.innerHTML = ""; // Clear the container
    contentRef.current.appendChild(slotHtml); // Append the new content
  }, [content]);

  return (
    <div
      className={
        cssClass
          ? ["module module__text", cssClass].join(" ")
          : "module module__text"
      }
    >
      <div className="module__box">
        <div className="module__text-content" ref={contentRef} />
      </div>
    </div>
  );
};

export default TextEditor;
